import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SvgIcon from '../../atoms/SvgIcon';
import { useOptions } from '../../../context/options';
import { ImageProp, SVGImageProp } from '../../../models/Section';
import SectionWrapper from '../../common/SectionWrapper';

import devices from '../../../js/styles/Devices';
import style from './style';

const ImageBlock = React.lazy(() => import('../../blocks/ImageBlock'));

const Title = styled.p`
  && {
    margin-bottom: 0.5em;
    text-align: ${(props) => props.position};
    font-family: var(--font-family);
    font-size: var(--h2-desktop-font-size);
    font-weight: var(--has-font-weight);
    letter-spacing: var(--has-letter-spacing);
    color: ${(props) => props.color};

    @media ${devices.downFromTablet} {
      font-size: var(--h2-mobile-font-size);
      line-break: ${style.lineBreak};
    }
  }
`;

const ExpandButton = styled.div`
  && {
    font-family: var(--font-family);
    background-image: none;
    border: 0;
    border-radius: ${style.borderRadius};
    color: ${style.color};
    cursor: pointer;
    font-weight: bold;
    text-decoration: ${style.textDecExpandBut};
    font-size: ${style.expandButtonFont};
    padding: ${style.expButPadding};
    display: ${style.displayExpButton};
    flex-direction: ${style.flexDirectExpBut};
    justify-content: ${style.justContExpBut};
    align-items: ${style.alignItExpBut};
    width: 100%;
    background-color: ${style.buttonBackgroundColor};
    border-color: ${style.borderColor};
    border-style: ${style.borderStyle};
    border-width: ${style.borderWidth};
    text-align: center;
    margin: ${style.margin};

    svg {
      margin-right: 0.2rem;
      padding-top: 0.2rem;
      font-size: 1rem;
    }

    @media ${devices.upFromTablet} {
      max-width: 30%;
    }
  }
`;

const TitleLine = styled.div`
  && {
    margin: 1.5em auto;
    width: 7em;
    height: 3px;
    background-color: var(--sambla-new-red);
    animation:enter .5s cubic-bezier(.25,.46,.45,.94) both;
    animation-delay: 1s;
  }
`;

const CreditorGrid = styled.div`
  && {
    display: grid;
    grid-template-columns: ${(props) => (props.numberOfCreditors > 6 ? props.creditorsColumnsGrid : `repeat(${props.numberOfCreditors}, 1fr)`)};
    grid-template-rows: 1fr;
    grid-gap: ${style.gridGap};
    padding-bottom: 1rem;
    align-items: center;


    @media ${devices.downFromTablet} {
      grid-template-columns: repeat(3, 1fr);
    }

    @media ${devices.mobile} {
      grid-template-columns: ${(props) => props.gridColumnMobile};
    }
  }
`;

const CreditorCard = styled.div`
  && {
    display: flex;
    align-items: center;
    background: ${(props) => props.backgroundColor};
    padding: 0.5rem;
    justify-content: center;
    min-height: 5rem;
    align-items: center;
  }
`;

const ImageContainer = styled(ImageBlock)`
  && {
    width: ${(props) => props.imageWidth};
    height: auto;
    margin: auto;
    max-height: ${(props) => props.maxHeight};
  }
`;

const Subtitle = styled.p`
  && {
    max-width: 35rem;
    font-size: var(--h4-desktop-font-size);
    margin: 0 auto 2rem 0;
  }
`;

const Icon = styled(SvgIcon)`
  && {
    margin-right: 0.2em;
    height: 1em;
    font-size: var(--text-font-size);
    padding-top: 0.2em;
    color: ${style.iconColor} !important;
  }
`;

const renderCreditor = (creditor, i) => {
  if (creditor.svgLogo || creditor.logo) {
    return (
      <CreditorCard
        backgroundColor={style.backgroundColor}
        key={`creditor-${i}`}
      >
        <ImageContainer
          imageWidth={style.imageWidth}
          maxHeight={style.maxHeight}
          imageObject={creditor.logo}
        />
      </CreditorCard>
    );
  }

  return null;
};

const renderMortgageCreditor = (mortgageCreditor, i) => {
  if (mortgageCreditor.svgLogo || mortgageCreditor.logo) {
    return (
      <CreditorCard
        backgroundColor={style.backgroundColor}
        key={`mortgageCreditor-${i}`}
      >
        <ImageContainer
          imageWidth={style.imageWidth}
          maxHeight={style.maxHeight}
          imageObject={mortgageCreditor.logo}
        />
      </CreditorCard>
    );
  }

  return null;
};

const renderExpandButton = (
  creditors,
  showAllCreditors,
  setShowAllCreditors,
) => (
  <ExpandButton
    id="creditorExpand"
    role="button"
    tabIndex={0}
    onClick={() => setShowAllCreditors(!showAllCreditors)}
  >
    <Icon
      icon={showAllCreditors
        ? ['fal', 'chevron-up']
        : ['fal', 'chevron-down']}
    />
    {showAllCreditors
      ? creditors.collapseButtonLabel
      : creditors.expandButtonLabel}
  </ExpandButton>
);

const renderAllCreditors = (creditors) => (
  <CreditorGrid
    gridColumnMobile={style.gridColumnMobile}
    creditorsColumnsGrid={style.creditorsColumnsGrid}
    numberOfCreditors={creditors.creditorsAcf.length}
    id="creditor-card"
  >
    {creditors.creditorsAcf.map((creditor, i) => renderCreditor(creditor, i))}
  </CreditorGrid>
);

const renderMortgageExpandButton = (
  mortgageCreditors,
  showAllMortgageCreditors,
  setShowAllMortgageCreditors,
) => (
  <ExpandButton
    id="mortgageExpand"
    role="button"
    tabIndex={0}
    onClick={() => setShowAllMortgageCreditors(!showAllMortgageCreditors)}
    borderRadius={style.borderRadius}
    margin={style.margin}
  >
    <Icon
      icon={showAllMortgageCreditors
        ? ['fal', 'chevron-up']
        : ['fal', 'chevron-down']}
    />
    {showAllMortgageCreditors
      ? mortgageCreditors.collapseButtonLabel
      : mortgageCreditors.expandButtonLabel}
  </ExpandButton>
);

const remToPixels = (rem) => {
  if (typeof window !== 'undefined' && window.getComputedStyle) {
    const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    return rem * fontSize;
  }
  return rem;
};

const PureCreditorsSection = ({
  creditors,
  mortgageCreditors,
  acf,
}) => {
  const showMortgage = acf.mortgage;
  const [showAllCreditors, setShowAllCreditors] = useState(false);
  const [showAllMortgageCreditors, setShowAllMortgageCreditors] = useState(false);

  const checkSizeWidth = devices.isLaptopWidth;
  const isWidthLaptop = remToPixels(checkSizeWidth);

  useLayoutEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < isWidthLaptop) {
      const dotsContainer = document.querySelector('.alice-carousel__dots');
      const dotsItems = dotsContainer ? dotsContainer.querySelectorAll('.alice-carousel__dots-item') : [];

      for (let i = 3; i < dotsItems.length; i += 1) {
        dotsItems[i].style.display = 'none';
      }
    }
  });

  const initiallyVisibleCreditors = showAllCreditors
    ? creditors.creditorsAcf
    : creditors.creditorsAcf.slice(0, style.creditorsColumns);

  const { titlePosition } = acf.sectionSettings;
  if (showMortgage) {
    const initiallyVisibleMortgageCreditors = showAllMortgageCreditors
      ? mortgageCreditors.mortgageCreditorsAcf
      : mortgageCreditors.mortgageCreditorsAcf?.slice(0, style.creditorsColumns);
    return (
      <SectionWrapper
        settings={acf.sectionSettings}
      >
        {acf.title && (
          <Title
            color={acf.sectionSettings.titleColor}
            position={titlePosition}
          >
            {acf.title}
          </Title>
        )}
        {acf.subtitle && (
          <Subtitle>{acf.subtitle}</Subtitle>
        )}
        {acf.sectionSettings?.titleLine && (
          <TitleLine />
        )}
        <>
          <CreditorGrid
            gridColumnMobile={style.gridColumnMobile}
            creditorsColumnsGrid={style.creditorsColumnsGrid}
            numberOfCreditors={initiallyVisibleMortgageCreditors.length}
            id="creditor-card"
          >
            {initiallyVisibleMortgageCreditors?.map((mortgageCreditor, i) => (
              renderMortgageCreditor(mortgageCreditor, i)
            ))}
          </CreditorGrid>

          {mortgageCreditors.mortgageCreditorsAcf?.length > style.creditorsColumns && (
            renderMortgageExpandButton(
              mortgageCreditors,
              showAllMortgageCreditors,
              setShowAllMortgageCreditors,
            )
          )}
        </>
      </SectionWrapper>
    );
  }

  return (
    <SectionWrapper settings={acf.sectionSettings}>
      {acf.title && (
        <Title
          position={titlePosition}
          color={acf.sectionSettings.titleColor}
        >
          {acf.title}
        </Title>
      )}
      {acf.subtitle && (
        <Subtitle>{acf.subtitle}</Subtitle>
      )}
      {acf.sectionSettings?.titleLine && (
        <TitleLine />
      )}
      <>
        <CreditorGrid
          gridColumnMobile={style.gridColumnMobile}
          creditorsColumnsGrid={style.creditorsColumnsGrid}
          numberOfCreditors={initiallyVisibleCreditors.length}
          id="creditor-card"
        >
          {initiallyVisibleCreditors.map((creditor, i) => (
            renderCreditor(creditor, i)
          ))}
        </CreditorGrid>

        {creditors.creditorsAcf.length > style.creditorsColumns ? (
          renderExpandButton(
            creditors,
            showAllCreditors,
            setShowAllCreditors,
          )
        ) : (
          renderAllCreditors(creditors)
        )}
      </>
    </SectionWrapper>
  );
};

export const CreditorsProps = {
  creditors: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    logo: PropTypes.shape({
      altText: PropTypes.string,
      image: ImageProp,
      svgImage: SVGImageProp,
    }),
  })),
  collapseButtonLabel: PropTypes.string,
  expandButtonLabel: PropTypes.string,
};

export const MortgageCreditorsProps = {
  mortgageCreditors: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    logo: PropTypes.shape({
      altText: PropTypes.string,
      image: ImageProp,
      svgImage: SVGImageProp,
    }),
  })),
  collapseButtonLabel: PropTypes.string,
  expandButtonLabel: PropTypes.string,
};

PureCreditorsSection.propTypes = {
  acf: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    sectionSettings: SectionWrapper.propTypes.settings,
    mortgage: PropTypes.bool,
  }).isRequired,
  creditors: PropTypes.shape(CreditorsProps).isRequired,
  mortgageCreditors: PropTypes.shape(MortgageCreditorsProps).isRequired,
};

const CreditorsSection = (props) => {
  const { optionCreditors, optionMortgageCreditors } = useOptions();
  return (
    <PureCreditorsSection
      {...props}
      creditors={optionCreditors}
      mortgageCreditors={optionMortgageCreditors}
    />
  );
};

export { PureCreditorsSection };

export default CreditorsSection;
