/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import queryString from 'query-string';
import moment from 'moment';
import styled from 'styled-components';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { MERGE_LOAN_FORM_DEFAULT_VALUES } from '../../../js/localizations/config/SE/advisa/form-default-values';
import { useGetApplicationQuery } from '../../../js/store/reducers/api-call';
import withTwoPassRendering from '../../utils/withTwoPassRendering';

import PrefilledApplicationProductBlock from '../../blocks/PrefilledApplicationProductBlock';
import LoadingSpinner from './LoadingSpinner';

const FormBlock = React.lazy(() => import('../../blocks/FormBlock'));

const PrefilledFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 35vh;
  padding: 0 1rem;
`;

const getUrlToken = (urlQueryString) => {
  const queryObject = queryString.parse(urlQueryString);
  return queryObject.t || null;
};

const PrefilledApplicationSection = ({
  acf,
  disclaimer,
  exampleLoan,
  location,
}) => {
  const queryParams = queryString.parse(location.search);

  const {
    data: application, isSuccess, isLoading, error,
  } = useGetApplicationQuery(
    !getUrlToken(location.search) ? skipToken : {
      t: getUrlToken(location.search),
      isCAPrefillToken: !!queryParams?.isCAPrefillToken,
      communicationChannel: queryParams?.channel,
    },
  );

  useEffect(() => {
    if (error != null) {
      const { errorMessage, response } = error;
      Sentry.setTag('origin', 'prefillApplication');

      Sentry.captureException(errorMessage, {
        stacktrace: true,
        fingerprint: ['getApplication', 'prefillApplication'],
        extra: {
          error: response,
        },
      });
    }
  }, [error]);

  const getAnnualIncomeBeforeTax = (tempApplication) => {
    const { monthlySalary, monthlyIncomeBeforeTax, monthlyIncomeAfterTax } = tempApplication;
    if (monthlySalary) {
      return parseInt(monthlySalary, 10) * 12;
    } if (monthlyIncomeBeforeTax) {
      return parseInt(monthlyIncomeBeforeTax, 10) * 12;
    } if (monthlyIncomeAfterTax) {
      return parseInt(monthlyIncomeAfterTax, 10) * 12;
    }

    return '';
  };

  const isCamelCase = (value) => {
    if (/^[a-z][a-zA-Z0-9]*$/.test(value)) {
      return true;
    }
    return false;
  };

  const checkImportedValue = (value) => {
    if (value.indexOf('_') > 0) {
      return value.toLowerCase().replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
    } if (isCamelCase(value)) {
      return value;
    }
    return value.toLowerCase();
  };

  const shouldExcludePPI = () => {
    if (typeof window === 'undefined') return false;

    if (window.location.hostname === 'localhost') return true;

    return window.location.pathname === '/preappsms-3/';
  };

  /* eslint-enable */
  let initialFormValues = {};
  if (isSuccess) {
    initialFormValues = {
      expanded: true,
      consolidationAmount: MERGE_LOAN_FORM_DEFAULT_VALUES.totalAmount,
      merge: true,
      applicant: {
        civilStatus: application?.civilStatus || '',
        email: application?.email || '',
        phoneNumber: application?.phoneNumber || '',
        dependants: application?.dependants === 'null'
          ? 0
          : (String(application?.dependants) || '0'),
        homeType: application?.homeType || '',
        homeCost: application?.homeCost || '',
        otherLivingCost: application?.otherLivingCost || '',
        monthlySalary: application?.monthlySalary || '',
        annualIncomeBeforeTax: getAnnualIncomeBeforeTax(application),
        monthlyIncomeBeforeTax: application?.monthlyIncomeBeforeTax || '',
        monthlyIncomeAfterTax: application?.monthlyIncomeAfterTax || '',
        employmentType: application?.employmentType || '',
        employmentSince: String(moment(application?.employmentSince).year() || ''),
        employmentSinceMonth: String(moment(application?.employmentSince).month() || (application?.employmentSinceMonth ? application.employmentSinceMonth : '')),
        employmentTo: (application?.employmentTo && String(moment(application?.employmentTo).year())) || '',
        // eslint-disable-next-line no-nested-ternary
        employmentToMonth: application?.employmentToMonth
          ? application.employmentToMonth
          : application?.employmentTo ? String(moment(application?.employmentTo).month()) : '',
        employerName: application?.employerName || '',
        employerPhone: application?.employerPhone || '',
        businessOrganizationNumber: application?.businessOrganizationNumber || '',
        employmentIndustry: application?.employmentIndustry
          ? checkImportedValue(application.employmentIndustry) : null,
        occupationCategory: application?.occupationCategory
          ? checkImportedValue(application.occupationCategory) : null,
        occupationTitle: application?.occupationTitle ? application?.occupationTitle : null,
        educationLevel: application?.educationLevel
          ? checkImportedValue(application?.educationLevel) : null,
        moveInDate: application?.moveInDate || '',
        citizenship: application?.citizenship ? application?.citizenship.toLowerCase() : null,
        homeTypeCategory: application?.homeTypeCategory
          ? checkImportedValue(application?.homeTypeCategory) : null,
        otherIncomeSource: application?.otherIncomeSource ? application?.otherIncomeSource : null,
        otherMonthlyIncomeAfterTax: application?.otherMonthlyIncomeAfterTax || '',
        otherMonthlyIncomeBeforeTax: application?.otherMonthlyIncomeBeforeTax || '',
        otherIncome: (application?.otherMonthlyIncomeAfterTax !== null
          || application?.otherMonthlyIncomeBeforeTax !== null),
        hasRentalIncome: !!application?.rentalMonthlyIncomeBeforeTax,
        spouseMonthlyIncomeBeforeTax: application?.spouseMonthlySalary || '',
        militaryServiceStatus: !!application?.militaryServiceStatus && application?.militaryServiceStatus !== null && typeof application?.militaryServiceStatus !== 'undefined'
          ? application.militaryServiceStatus.toLowerCase() : null,
        rentalMonthlyIncomeBeforeTax: application?.rentalMonthlyIncomeBeforeTax || '',
        spouseAnnualIncomeBeforeTax: application?.spouseMonthlySalary || '',
      },
    };

    const removePPIDetails = shouldExcludePPI();
    if (removePPIDetails) {
      initialFormValues.applicant.email = '';
      initialFormValues.applicant.phoneNumber = '';
      initialFormValues.applicant.employerName = '';
      initialFormValues.applicant.employerPhone = '';
    }
  }

  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let timer;
    if (acf.form.show && !isLoading) {
      timer = setTimeout(() => {
        setShowForm(true);
        setLoading(false);
      }, 3000); // 3-second delay
    } else {
      setLoading(true);
      setShowForm(false);
    }

    return () => clearTimeout(timer);
  }, [acf.form.show, isLoading]);

  return (
    <>
      <PrefilledApplicationProductBlock
        {...acf}
      />
      <PrefilledFormWrapper>
        {loading && <LoadingSpinner />}
        {showForm && !loading && (
          <FormBlock
            {...acf.form}
            prefilledValues={initialFormValues}
            disclaimer={disclaimer}
            fullWidth
            elevated={false}
            rounded={false}
            exampleLoan={exampleLoan}
            prefilledApplicationForm
          />
        )}
      </PrefilledFormWrapper>
    </>
  );
};

PrefilledApplicationSection.propTypes = {
  application: PropTypes.shape(),
  disclaimer: PropTypes.string,
  exampleLoan: PropTypes.shape(),
  loading: PropTypes.bool,
  acf: PropTypes.shape({
    form: PropTypes.shape(FormBlock.propTypes).isRequired,
  }).isRequired,
  location: PropTypes.shape().isRequired,
};

PrefilledApplicationSection.defaultProps = {
  application: {},
  disclaimer: null,
  exampleLoan: null,
  loading: true,
};

export { PrefilledApplicationSection };

export default (
  withTwoPassRendering(PrefilledApplicationSection)
);
