import React from 'react';
import PropTypes from 'prop-types';

import * as Sentry from '@sentry/browser';
import { SubmissionError } from 'redux-form';
import styled from 'styled-components';
import store from '../../../js/store';
import SectionWrapper from '../../common/SectionWrapper';
import devices from '../../../js/styles/Devices';
import sizes from '../../../js/styles/Sizes';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';
import { useEmailSubmissionMutation } from '../../../js/store/reducers/api-call';
import localeData from '../../../js/localizations/locale-data';

const { EmailSubmissionForm } = localeData.COMPONENTS;

const EmailSubmissionBlockStyled = styled.div`
  && {
    display: flex;
    flex-direction: column;
    background: var(--off-white-tint-50);
    border-radius: ${sizes.defaultBorderRadius};
    padding: 2rem;

    @media ${devices.upFromTablet} {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

const EmailSubmissionText = styled.div`
  && {
    flex: 1;
    padding-bottom: 1rem;
    text-align: center;

    @media ${devices.upFromTablet} {
      text-align: left;
      padding-bottom: 0;
    }

    a {
      color: var(--link-color);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0.4rem 0;

        &::before {
          color: var(--teal);
          content: '.';
          font-weight: bold;
          margin-right: 0.6rem;
        }
      }

      table {
        border-collapse: collapse;
        width: 100%;

        &:not(:first-child) {
          margin-top: 1rem;
        }
      }

      tr {
        &:nth-child(odd) {
          background: var(--lightest-grey);
        }
      }

      td {
        padding: 0.5rem 0.8rem;
      }

      img {
        height: auto;
        max-width: 100%;
      }
    }
  }
`;

const PolicyText = styled.div`
  && {
    display: block;
    margin: 1rem auto;
    a {
      color: var(--link-color);
    }
    p {
      font-size: 0.7rem;
    }
  }
`;

const EmailSubmissionFormStyled = styled.div`
  && {
    flex: 1;

    button {
      min-width: 7rem!important;
      background-color: var(--mpc);
    }

    @media ${devices.upFromTablet}{
      margin-left: 2rem;
    }
  }
`;

const EmailSubmissionSection = ({
  acf,
  errorMessage,
}) => {
  const [emailSubmission, result] = useEmailSubmissionMutation();
  const formState = result;

  const submit = () => {
    const state = store.getState();
    const email = state?.form?.emailSubmission?.values?.email;
    emailSubmission({ email })
      .unwrap()
      .catch(({ sentryErrorMessage, response, payload }) => {
        Sentry.setTag('origin', 'callMe');
        Sentry.captureException(sentryErrorMessage, {
          stacktrace: true,
          fingerprint: ['submit', 'onSubmitApplication'],
          extra: {
            error: response,
            payload,
          },
        });
        throw new SubmissionError({ _error: errorMessage });
      });
  };

  return (
    <SectionWrapper settings={acf.sectionSettings}>
      <EmailSubmissionBlockStyled>
        {acf.text && (
        <EmailSubmissionText
          dangerouslySetInnerHTML={createMarkup(acf.text)}
        />
        )}
        <EmailSubmissionFormStyled>
          <EmailSubmissionForm
            form="emailSubmission"
            formState={formState}
            inputPlaceholder={acf.inputPlaceholder}
            inputLabel={acf.inputLabel}
            buttonLabel={acf.buttonLabel}
            errorMessage={acf.errorMessage}
            successMessage={acf.successMessage}
            mobile={acf.squished}
            isTablet={acf.isTablet}
            onSubmit={submit}
          />
          <PolicyText
            dangerouslySetInnerHTML={createMarkup(acf.policyText)}
          />
        </EmailSubmissionFormStyled>
      </EmailSubmissionBlockStyled>
    </SectionWrapper>
  );
};

EmailSubmissionSection.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  acf: PropTypes.shape({
    text: PropTypes.string,
    policyText: PropTypes.string.isRequired,
    inputPlaceholder: PropTypes.string.isRequired,
    inputLabel: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    successMessage: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    squished: PropTypes.bool,
    isTablet: PropTypes.bool,
    sectionSettings: SectionWrapper.propTypes.settings,
  }).isRequired,
  isTablet: PropTypes.bool.isRequired,
};

export default EmailSubmissionSection;
