const formatLink = (url, lang, canonical) => {
  if (!url) {
    return '';
  }

  if (canonical) {
    return url;
  }

  const match = url.match(/^(https?:\/\/.+?(?=\/|$))?(.*)$/);

  if (!match[2]) {
    throw new Error(`${url} is not a valid url`);
  }

  // Don't format external links
  if (process.env.GATSBY_FRONTEND_BASE_URL !== match[1]) {
    return url;
  }

  let path = match[2].replace(`/${lang}/`, '');

  const pathHasHashTag = path.indexOf('#') !== -1;

  if (pathHasHashTag) {
    path = path
      .split('#')
      .map((uriSegment) => uriSegment.replace(/\/$/, ''))
      .join('#');
    return `/${path}`;
  }

  path = path.replace(/^\/*(.*?)\/*$/, '$1');

  return `/${path}/`;
};

export default formatLink;
